import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
class NotFoundPage extends React.Component {
  render() {
    if (typeof window !== 'undefined') {
      window.location = '/'
    }
    return (
      <Layout
        children={
          <h1>
            Page not found.. <Link to={'/'}>Go Home</Link>
          </h1>
        }
      />
    )
  }
}

export default NotFoundPage
